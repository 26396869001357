<template>
  <div class="video-list-skeleton">
    <VideoCardSkeleton
      v-for="index in 9"
      :key="index"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VideoCardSkeleton from '@/components/VideoCardSkeleton'

export default {
  name: 'VideoListSkeleton',
  components: {
    VideoCardSkeleton
  }
}
</script>

<style lang="sass">
.video-list-skeleton
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 32px
  padding: 12px 64px

  +mq-l
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 24px

  +mq-s
    grid-template-columns: repeat(1, 1fr)
    grid-gap: 16px
    padding: 12px 24px 12px 24px
</style>
