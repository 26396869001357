<template>
  <div
    class="video-card"
    @click="redirectToVideo(video)"
  >
    <div class="video-card__header">
      <div class="video-card__header__badge">
        <div
          :style="{background: cardBackgroundColor}"
          class="duration-frame"
        >
          <img
            :src="thumbnail"
            class="video-card__thumbnail"
          >
          <div class="video-duration">
            {{ duration }}
          </div>
        </div>
        <div class="card__title">
          <h2>{{ title }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import thumbnail from '@/assets/thumbnail.svg'

export default {
  name: 'VideoCard',
  props: {
    video: {
      type: Object,
      required: true
    }
  },
  computed: {
    title() {
      return this.video.title
    },

    thumbnail() {
      return this.video.thumbnailUrl || thumbnail
    },

    duration() {
      const duration = this.video.duration
      const hours = `${Math.floor((duration / 3600))}`.padStart(2, '0')
      const minutes =  `${Math.floor(duration % 3600 / 60)}`.padStart(2, '0')
      const seconds =  `${Math.floor(duration % 3600 % 60)}`.padStart(2, '0')

      return `${(+hours > 0) ? `${hours}:` : ''}${minutes}:${seconds}`
    },

    cardBackgroundColor() {
      const defaultColor = '#2CA4E8'
      const selectedCategory = localStorage.getItem('selectedCategory')
      return selectedCategory ? JSON.parse(selectedCategory).color : defaultColor
    }
  },
  methods: {
    redirectToVideo(video) {
      this.$router.push({
        name: 'video',
        params: {
          channel: this.$route.params.channel,
          video: video.id,
        },
      })
    }
  }
}
</script>

<style lang="sass">
.video-card
  display: flex
  flex-direction: column
  align-items: flex-start
  border-radius: 8px
  backface-visibility: hidden
  position: relative
  height: 100%
  width: 100%
  padding: 8px 0
  box-sizing: border-box
  cursor: pointer
  +anim(200ms)

  +mq-s
    padding: 20px 0

  &.tour-active
    &:before
      width: calc(100% + 15px)
      height: calc(100% + 15px)
      background: $color-ice

  &__video-id
    display: none

  &:hover
    +anim(200ms)
    box-shadow: 0px 5px 12px rgba(159, 159, 212, 0.35)

  &__header
    width: 100%
    box-sizing: border-box
    align-items: center
    display: flex

    &__badge
      background: none !important
      padding-left: 5px
      display: block
      width: 100%

  .card__title
    width: 100%
    box-sizing: border-box
    color: $color-main
    padding-top: 4px

    h2
      font-size: 16px
      color: $color-main

      +mq-s
        bottom: 20px

    h2
      +mq-m
        font-size: 18px

      +mq-s
        font-size: 18px

  .badge
    text-align: left
    padding: 3px

  &__thumbnail
    border-radius: 3%
    width: 100%
    object-fit: contain

    +mq-m
      max-width: 100%

    +mq-s
      max-width: 100%

.duration-frame
  position: relative
  min-height: 146px
  min-width: 260px
  width: 100%
  border-radius: 3%
  font-size: 0

  +mq-s
    min-height: 185px
    min-width: 330px

  +mq-xs
    min-height: 146px
    min-width: 260px

.video-duration
  padding: 0 4px
  height: 26px
  position: absolute
  bottom: 6px
  right: 6px
  color: #FFFFFF
  background-color: $color-ink
  border-radius: 5px
  letter-spacing: .44px
  font-size: 13px
  font-weight: 600
  line-height: 1.8rem
  text-align: center
  opacity: 0.9
</style>
