<template>
  <div class="resource-not-found">
    <img
      class="resource-not-found__icon"
      src="~@/assets/sad-tv.svg"
      alt="icon sad tv"
    >
    <h4>Não foi possível carregar este conteúdo.</h4>
    <label>Por favor, tente recarregar a página ou tente novamente mais tarde.</label>
    <TVButton @click="$emit('refresh')">
      <Icon type="rotate-cw" />
      <h5>Recarregar</h5>
    </TVButton>
  </div>
</template>

<script>
export default {
  name: 'ResourceNotFound'
}
</script>

<style lang="sass">
.resource-not-found
  display: flex
  align-items: center
  flex-direction: column
  padding: 24px
  text-align: center

  button
    margin-bottom: 20px

    h5
      padding-left: 10px

  &__icon
    height: 38px
    padding: 12px 0
    color: $color-main

  h4
    color: $color-main

  label
    padding: 12px 0

</style>
