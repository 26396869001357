<template>
  <div class="videos-body">
    <ContentHeader
      :title="header.title"
      :color="header.color"
      :logo="header.icon"
      :show-back-button="true"
      :show-logo-when-small="false"
      :show-logo-when-big="header.showWhenBig"
      :back-button-name="headerPreviousTitle"
      @getBack="removeSelectedCategory"
    />
    <TVWrapper>
      <div class="videos-body__title">
        <Icon type="film" />
        <h2 class="videos-body__title__label">
          Todos os Vídeos
        </h2>
      </div>

      <VideoListSkeleton v-if="loading" />
      <div
        v-if="isSuccessful"
        class="videos-body__video-list"
      >
        <VideoCard
          v-for="(video, index) in videos"
          :id="index === 0 ? 'v-step-5' : ''"
          :key="video.id"
          :video="video"
          @click.native="$emit('click', video)"
        />
      </div>

      <VideoEmptyState
        v-if="showEmptyState"
        @getBack="removeSelectedCategory"
      />
      <ResourceNotFound
        v-if="isUnsuccessful"
        @refresh="loadVideos()"
      />
    </TVWrapper>
    <TourVideo
      v-if="isTourActive && isSuccessful"
      :first-video-id="videos[0].id"
      @skip="skipTour"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ContentHeader from '@/components/ContentHeader'
import VideoCard from '@/components/VideoCard'
import VideoListSkeleton from '@/components/VideoListSkeleton'
import ResourceNotFound from '@/components/ResourceNotFound'
import VideoEmptyState from '@/components/VideoEmptyState'
import loadHeader from '@/mixins/loadHeader'
import tour from '@/mixins/tour'
const TourVideo = () => import('@/components/TourVideo')

export default {
  name: 'VideoList',
  components: {
    ContentHeader,
    VideoCard,
    VideoListSkeleton,
    ResourceNotFound,
    VideoEmptyState,
    TourVideo,
  },
  mixins: [
    loadHeader,
    tour,
  ],
  data() {
    return {
      loading: false,
      successful: false,
      videos: [],
      header: {
        showWhenBig: true
      },
      showEmptyState: false
    }
  },
  computed: {
    ...mapGetters([
      'selectedCategories',
    ]),
    isSuccessful() {
      return !this.loading && this.successful
    },
    isUnsuccessful() {
      return !this.loading && !this.successful && !this.showEmptyState
    },
    headerPreviousTitle() {
      return this.selectedCategories.length > 1 ? this.selectedCategories[this.selectedCategories.length - 2].name : 'Anterior'
    },
  },
  mounted() {
    this.loadVideos()
    this.clearCategories()
  },
  methods: {
    ...mapActions([
      'getVideosByChannel',
      'removeSelectedCategory',
      'clearSelectedCategories',
    ]),
    async loadVideos() {
      this.loading = true
      try {
        const videos = await this.getVideosByChannel({
          channelId: this.$route.params.channel,
          query: this.$route.query.properties || []
        })

        if (videos && videos.length) {
          this.videos = videos
          this.successful = true
        } else {
          this.showEmptyState = true
        }
      } catch (e) {
        this.successful = false
      }
      this.loading = false
    },
    clearCategories() {
      if (this.$route.query.qrcode) {
        localStorage.removeItem('selectedCategory')
        this.clearSelectedCategories()
        this.header = {
          title: undefined,
          color: undefined,
          icon: null,
        }
      }
    }
  }
}
</script>

<style lang="sass">
  .videos-body
    .wrapper
      min-height: 80vh
      background: $color-ice

      +mq-s
        margin: 0
        max-width: 100%

    &__title
      padding: 50px 64px 0
      display: flex

      +mq-s
        padding: 40px 24px 12px

      &__label
        font-size: 20px
        color: $color-main
        padding-left: 5px

    &__video-list
      display: grid
      grid-template-columns: repeat(3, 1fr)
      grid-gap: 32px
      padding: 12px 64px

      +mq-l
        grid-template-columns: repeat(2, 1fr)
        grid-gap: 24px

      +mq-s
        grid-template-columns: repeat(1, 1fr)
        grid-gap: 16px
        padding: 12px 24px 12px 24px
</style>
