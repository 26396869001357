<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      onboardingCurrentUser: {},
    }
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'onboarding',
    ]),
    ...mapState('profile', ['personalData']),
    isTourActive() {
      this.setUser()

      if (this.onboardingCurrentUser) {
        return (
          this.onboardingCurrentUser.tourActive
            && !this.onboardingCurrentUser.welcomeActive
            && !this.isLoading
        )
      }

      return false
    },
  },
  created() {
    this.setUser()
  },
  methods: {
    ...mapActions([
      'setOnboarding',
    ]),
    skipTour() {
      this.setOnboarding({
        ...this.onboarding,
        users: {
          ...this.onboarding.users,
          [this.personalData.id]: {
            ...this.onboardingCurrentUser,
            id: this.personalData.id,
            welcomeActive: false,
            tourActive: false,
          },
        },
      })
    },
    setUser() {
      this.onboardingCurrentUser = this.onboarding.users[this.personalData.id]
    },
  },
}
</script>
