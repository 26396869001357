<template>
  <div class="channel">
    <VideoList />
  </div>
</template>

<script>
import VideoList from '@/components/VideoList'

export default {
  name: 'Channel',
  components: {
    VideoList
  }
}
</script>

<style lang="sass">

</style>
