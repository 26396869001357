<template>
  <div class="empty-state">
    <img
      class="empty-state__icon"
      src="~@/assets/icon-video-empty-state.svg"
      alt="icon video empty state"
    >
    <h4>Em breve!</h4>
    <div class="empty-state__text">
      <label>Estamos preparando tudo para oferecer o melhor do conteúdo SAS Educação TV.</label>
      <label>Enquanto isso, explore outros canais disponíveis.</label>
    </div>
    <TVButton @click="getBack">
      <Icon type="arrow-left" />
      <h5>Voltar</h5>
    </TVButton>
  </div>
</template>

<script>
export default {
  name: 'VideoEmptyState',
  methods: {
    getBack() {
      this.$router.go(-1)
      this.$emit('getBack')
    }
  }
}
</script>

<style lang="sass">
.empty-state
    display: flex
    align-items: center
    flex-direction: column
    padding: 24px
    text-align: center

    button
        h5
            padding-left: 10px

    &__icon
        height: 250px
        padding: 12px 0
        color: $color-main

        +mq-s
            height: 200px
    h4
        margin-top: 15px
        color: $color-main

    &__text
        padding: 12px 0
        margin: 10px 0

</style>
